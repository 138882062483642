<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row class="mb-4">
        <v-col cols="12">
          <h1 class="text-h3">{{ $t("about") }}</h1>
          <Underline />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4 secondary--text pa-1">{{ $t("what") }}</h2>
          <p class="text-body-1 pa-1">{{ $t("what_paragraph") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4 secondary--text pa-1">{{ $t("mission") }}</h2>
          <p class="text-body-1 pa-1">{{ $t("mission_paragraph") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4 secondary--text pa-1">{{ $t("beliefs") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("knowledge") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("knowledge_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("together") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("together_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("fair") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("fair_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("tech_industry") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("tech_industry_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12">
          <h1 class="text-h3">{{ $t("who") }}</h1>
          <Underline />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-list-item-avatar size="140" class="hidden-md-and-up">
            <v-img src="/drew_terry.jpg"></v-img>
          </v-list-item-avatar>
          <v-list-item>
            <v-list-item-avatar size="140" class="hidden-sm-and-down">
              <v-img src="/drew_terry.jpg"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-3"
                >{{ $t("profile_name_drew") }}
                <v-btn
                  icon
                  href="https://twitter.com/drewpterry"
                  color="secondary"
                  ><v-icon>mdi-twitter</v-icon></v-btn
                >
                <v-btn
                  icon
                  href="https://www.linkedin.com/in/drewpterry/"
                  color="secondary"
                  ><v-icon>mdi-linkedin</v-icon></v-btn
                >
              </v-list-item-title>
              <v-list-item-subtitle class="mb-3">
                {{ $t("profile_subtitle_drew") }}
              </v-list-item-subtitle>
              {{ $t("profile_description_1") }}
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12">
          <v-list-item-avatar size="140" class="hidden-md-and-up">
            <v-img src="/toshiaki_tominari.png"></v-img>
          </v-list-item-avatar>
          <v-list-item>
            <v-list-item-avatar size="140" class="hidden-sm-and-down">
              <v-img src="/toshiaki_tominari.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-3"
                >{{ $t("profile_name_yurie") }}
                <v-btn
                  icon
                  href="https://twitter.com/tominarit"
                  color="secondary"
                  ><v-icon>mdi-twitter</v-icon></v-btn
                >
                <v-btn
                  icon
                  href="https://www.linkedin.com/in/tominarit/"
                  color="secondary"
                  ><v-icon>mdi-linkedin</v-icon></v-btn
                >
              </v-list-item-title>
              <v-list-item-subtitle class="mb-3">
                {{ $t("profile_subtitle_yurie") }}
              </v-list-item-subtitle>
              {{ $t("profile_description_2") }}
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "about": "About",
    "what": "What is OpenSalary?",
    "what_paragraph": "Salary information anonymously provided by the developer community in Japan. This is a community driven platform made by developers, for the benefit of developers.",
    "mission": "Our Mission",
    "mission_paragraph": "Empower software engineers with the data they need to make informed career decisions.",
    "beliefs": "Our Beliefs",
    "knowledge": "Knowledge is Power",
    "knowledge_explanation": "Power imbalances occur when certain groups don't have the same access to information other groups do. We want to even the playing field by giving equal access to this information.",
    "together": "We Can Achieve More Together",
    "together_explanation": "We rely on the developer community to help provide data. Each of us have knowledge that can be shared to help everyone else.",
    "fair": "People Should Be Paid Fairly",
    "fair_explanation": "We believe developers should be compensated fairly within companies for their skills and overall contribution to the company. Not based on nationality, race, gender, location or their previous salary.",
    "tech_industry": "We Believe in Japan's Tech Industry",
    "tech_industry_explanation": "There is a lot of engineering talent in Japan and we want to see the average salaries of software engineers in Japan rise to more competitive levels across the industry.",
    "who": "Who made this?",
    "profile_name_drew": "Drew Terry",
    "profile_name_yurie": "Toshiaki Tominari",
    "profile_subtitle_drew": "Product Manager & Software Engineer",
    "profile_subtitle_yurie": "Product Manager",
    "profile_description_1": "I grew up in California and have spent my whole life around the tech industry in SF and Silicon Valley. In 2015 I became a software engineer and moved to Japan, where I've been since then. As I got more exposure to Japan's tech scene both through work and developer communities, I started noticing a significant difference between the US and Japan regarding transparency and access to information about companies, especially around compensation and wages. This information disparity is what motivated me to create OpenSalary.jp, which I hope will become a useful tool to empower tech workers in Japan with the information they need to make informed career decisions.",
    "profile_description_2": "I was born and raised in Japan but spent 10 years of my life in the UK. I have a background in B2B sales and software engineering, before transitioning into product management for the last 5+ years. I believe everyone should be given equal information to make the best decisions in their careers, and OpenSalary is the first step toward meeting that goal. I have an MBA from Durham University in the UK."
  },
  "ja": {
    "about": "About",
    "what": "OpenSalaryとは",
    "what_paragraph": "OpenSalaryは、開発者のための開発者によって作られたコミュニティドリブンのプラットフォームとして、日本で働く開発者から匿名で集められた給与情報を提供します。",
    "mission": "ミッション",
    "mission_paragraph": "キャリアの意思決定に必要となるデータを提供しソフトウェア開発に携わるエンジニアをエンパワメントする",
    "beliefs": "信念",
    "knowledge": "情報は力なり",
    "knowledge_explanation": "平等な情報アクセスの機会がないことで力の不均衡は起こります。OpenSalaryは、私たちが提供する情報に誰もが平等にアクセスできることで均等な機会を作りたいと考えています。",
    "together": "協力すればより大きな達成に",
    "together_explanation": "OpenSalaryが提供するデータは開発者コミュニティに支えられています。あなたが持っている知識を共有することで他の誰かの役に立つことができます。",
    "fair": "適正な給与が支払われるべき",
    "fair_explanation": "OpenSalaryは、国籍、人種、性別、地域、前職の年収ではなく、持つスキル、働く企業への貢献度に見合った適正な報酬が開発者に支払われるべきだと信じています。",
    "tech_industry": "日本のテック業界を信じて",
    "tech_industry_explanation": "日本には多くの優秀なエンジニアがいます。日本で働くソフトウェアエンジニアの平均給与が引き上がり、日本のテック業界が国際的な競争力を持つことを期待します。",
    "profile_name": "Drew Terry",
    "profile_name_yurie": "Toshiaki Tominari",
    "profile_subtitle_drew": "テクニカルプロダクトマネージャー＆ソフトウェアエンジニア",
    "profile_subtitle_yurie": "プロダクトマネージャー",
    "profile_description_1": "カリフォルニア出身。サンフランシスコとシリコンバレーのテック業界で大半を過ごす。2015年にソフトウェアエンジニアとして日本企業で働くことをきっかけに、仕事の場を日本へ移す。仕事と開発者コミュニティを通して日本のテックシーンに触れる機会が増えるなかで、アメリカと日本では年収と給与情報の透明性に大きな違いがあることに気づく。この気づきをきっかけに、均等な情報アクセスの機会を提供したいと思うようになり、OpenSalary.jpを立ち上げる。このプラットフォームがキャリアの意思決定に必要な情報を提供し、日本のテック業界で働く人をエンパワメントするツールになることを願っています。",
    "profile_description_2": "愛知県出身。英ダラム大学ビジネススクールMBA卒業。10歳から単身留学を決意し渡英。大学卒業後に帰国、様々な大手及びスタートアップ企業にて広告営業、ソフトウェアエンジニアとして経験を積み、現在はシニアプロダクトマネージャーとして活躍。全ての就業者に平等な情報を提供し、最善のキャリアを歩む手助けをしたいと願いOpenSalary.jpの運営に取り組む。"
    }
}
</i18n>

<script>
import Underline from "components/Underline.vue";

export default {
  components: {
    Underline
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: "About",
      meta: [
        {
          vmid: "description",
          property: "description",
          content: this.$t("what_paragraph")
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>
